import { Preview, Save } from '@mui/icons-material';
import { Button, Checkbox, FormControlLabel, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './QuillStyle.css';
import axios from 'axios';
import { addTemplate, getTemplateById, updateTemplate } from '../../services/api';
import { IOrganizarTemplates } from './interfaces/IOrganizarTemplates';
import { useNavigate, useParams } from 'react-router-dom';

const CriadorTemplates: React.FC = () => {
  const [templateData, setTemplateData] = useState<IOrganizarTemplates | null>(null);
  const [emailType, setEmailType] = useState<string>(templateData?.Tipo || "");
  const [emailSubject, setEmailSubject] = useState<string>(templateData?.Email_Assunto || "");
  const [html, setHtml] = useState<string>(templateData?.Email_Template || "");
  const [active, setActive] = useState<boolean>(templateData?.Ativo || false);
  const [diasEnvio, setDiasEnvio] = useState<number>(templateData?.Dias_Para_Envio || 0);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<string | null>(null);
  const quillRef = useRef<ReactQuill | null>(null);
  const { id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      const fetchTemplateData = async () => {
        try {
          const response = await getTemplateById(id);
          console.log(response);
          const data = response.data;
          setTemplateData(data);
          setEmailType(data.tipo);
          setEmailSubject(data.email_Assunto);
          setActive(data.ativo);
          setDiasEnvio(data.dias_Para_Envio);
          const originalHtml = data.email_Template;
          const contentToEdit = originalHtml.match(/<div class="content"[^>]*>([\s\S]*?)<\/div>/);
          const content = contentToEdit ? contentToEdit[1] : "";
          setHtml(content);
        } catch (error) {
          console.error("Erro ao buscar template: ", error);
        }
      }
      fetchTemplateData();
    }
  }, [id]);
  
  

  const handleSubjectChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setEmailSubject(e.target.value);
  };

  const handleTypeChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setEmailType(e.target.value);
  }

  const handleChange = (value: string) => {
    setHtml(value);
  };

  const handleActive = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setActive(checked);
  }

  const handleDiasChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDiasEnvio(Number(e.target.value));
  };

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }


  const handleSavetemplate = async () => {
    try {
      handleOpen();
      const currentYear = new Date().getFullYear();
  
      const styledHTML = `
        <style type="text/css"></style> 
        <table width="100%" style="font-family: Helvetica, Arial, sans-serif; padding-top: 40px; margin:0; padding:0; -webkit-font-smoothing: antialiased" cellspacing="0" cellpadding="0" border="0" align="center"> 
          <tbody> 
            <tr> 
              <td> 
                <table width="90%" cellspacing="0" cellpadding="0" border="0" align="center" style="margin-top: 50px; margin-bottom: 100px;"> 
                  <tbody> 
                    <tr> 
                      <td width="800" bgcolor="#F3F3F3" style="padding: 15px;"> 
                        <table width="800" cellspacing="0" cellpadding="0" border="0" align="center"> 
                          <tbody> 
                            <tr> 
                              <td width="800" bgcolor="#ffffff" style="border: 1px solid #e7eeee; border-radius: 5px;"> 
                                <table width="800" cellspacing="0" cellpadding="0" border="0" align="center" style="margin-top: 40px;"> 
                                  <table width="600" cellspacing="0" cellpadding="0" border="0" align="center" style="margin-top: 40px;"> 
                                    <tbody> 
                                      <tr> 
                                        <td width="800" style="padding-bottom: 40px; border-bottom: 1px solid #e7eeee;" colspan="2"> 
                                          <center> 
                                            <img border="0" style="height: 100px;" alt="Logo Guarida" src="https://storage.googleapis.com/storage_geral/imagens/logo/logo.png"> 
                                          </center> 
                                        </td> 
                                      </tr> 
                                      <tr> 
                                        <td width="800" style="font-size: 14px; color: #65707a; text-align: left; padding-top:20px; padding-bottom: 20px" colspan="2"> 
                                          <table style="font-size: 14px; color: #65707a; text-align: left; padding: 20px;"> 
                                            <div style="margin: 0 auto;"> 
                                              <div class="content" style="margin: 1em;">${html}</div>
                                            </div> 
                                          </table>
                                        </td> 
                                      </tr> 
                                      <tr> 
                                        <td width="50%" style="padding-top: 20px; padding-bottom: 20px; border-top: 1px solid #e7eeee; color:#4D4D4D"></td> 
                                      </tr> 
                                      <tr> 
                                        <td width="800" style="padding-top: 20px; padding-bottom: 20px; color:#4D4D4D" colspan="2"> 
                                          <center> 
                                            <font style="font-size: 12px"> &copy; Guarida ${currentYear} - Todos os direitos reservados. </font> 
                                          </center> 
                                        </td> 
                                      </tr> 
                                    </tbody> 
                                  </table> 
                                </table> 
                              </td> 
                            </tr> 
                          </tbody> 
                        </table> 
                      </td> 
                    </tr> 
                  </tbody> 
                </table> 
              </td> 
            </tr> 
          </tbody> 
        </table>`;
  
      const templateData: IOrganizarTemplates = {
        Tipo: emailType,
        Ativo: active, 
        Email_Template: styledHTML,
        Email_Assunto: emailSubject,
        From_Manager_Email: true,
        Dias_Para_Envio: diasEnvio
      };
  
      if (id) {
        await updateTemplate(id, templateData);
        setMessage('Template atualizado com sucesso.');
      } else {
        const response = await addTemplate(templateData);
        setMessage(response.data.message);
      }
  
      // Resetando os campos após salvar
      setEmailType("");
      setEmailSubject("");
      setHtml("");
      setActive(false);
      setDiasEnvio(0);
  
      if (id) {
        setTimeout(() => {
          navigate("/templatesemail/OrganizarTemplates/");
        }, 1000);
      }
      
    } catch (error) {
      setMessage("Erro ao salvar o template.");
      console.error("Erro ao salvar o template:", error);
    }
  };
  

  const openPreviewInNewTab = () => {
    const newWindow = window.open();
    if (newWindow) {
      newWindow.document.write(`
        <style type="text/css">
          body {
            font-family: Helvetica, Arial, sans-serif;
            -webkit-font-smoothing: antialiased;
            padding: 0;
            margin: 0;
            width: 100%;
          }
          .content {
            max-width: 800px;
            margin: 0 auto;
            padding: 20px;
            word-wrap: break-word;
            white-space: pre-wrap;
          }
        </style>
        <table width="100%" style="padding-top: 40px; margin:0; padding:0;" cellspacing="0" cellpadding="0" border="0" align="center">
          <tbody>
            <tr>
              <td>
                <table width="90%" cellspacing="0" cellpadding="0" border="0" align="center" style="margin-top: 50px; margin-bottom: 100px;">
                  <tbody>
                    <tr>
                      <td width="800" bgcolor="#F3F3F3" style="padding: 15px;">
                        <table width="800" cellspacing="0" cellpadding="0" border="0" align="center">
                          <tbody>
                            <tr>
                              <td width="800" bgcolor="#ffffff" style="border: 1px solid #e7eeee; border-radius: 5px;">
                                <table width="800" cellspacing="0" cellpadding="0" border="0" align="center" style="margin-top: 40px;">
                                  <table width="600" cellspacing="0" cellpadding="0" border="0" align="center" style="margin-top: 40px;">
                                    <tbody>
                                      <tr>
                                        <td width="800" style="padding-bottom: 40px; border-bottom: 1px solid #e7eeee;" colspan="2">
                                          <center> 
                                            <img border="0" style="height: 100px;" alt="Logo Guarida" src="https://storage.googleapis.com/storage_geral/imagens/logo/logo.png">
                                          </center>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td width="800" style="font-size: 12px; color: #65707a; text-align: left; padding-top:20px; padding-bottom: 20px;" colspan="2">
                                          <div class="content">
                                            <p>${html}</p>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td width="50%" style="padding-top: 20px; padding-bottom: 20px; border-top: 1px solid #e7eeee; color:#4D4D4D"></td>
                                      </tr>
                                      <tr>
                                        <td width="800" style="padding-top: 20px; padding-bottom: 20px; color:#4D4D4D" colspan="2">
                                          <center>
                                            <font style="font-size:10px"> &copy; Guarida ${new Date().getFullYear()} - Todos os direitos reservados. </font>
                                          </center>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      `);
      newWindow.document.close();
      newWindow.focus();
    }
  };
  
  const modules = {
    toolbar: [[{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ["link", "image"],
    [{ color: [] }, { background: [] }],]
  }

  return (<>
      <div className='textfields'>
        <TextField
        label = "Assunto do e-mail"
        placeholder = "Insira o assunto do e-mail..."
        value = {emailSubject}
        onChange={handleSubjectChange}
        sx={{ 
        width: "350px", 
        marginLeft: "20px"
        }}
        />
        <TextField
        label = "Tipo e-mail"
        placeholder = "Renovação, condominio, inadimplêcia, etc..."
        value = {emailType}
        onChange={handleTypeChange}
        sx={{ 
        width: "350px", 
        marginLeft: "20px"
        }}
        />
        <TextField 
        label = "Dias para envio"
        type="number"
        value={diasEnvio}
        onChange={handleDiasChange}
        sx = {{
          marginLeft: "10px"
        }}
        />
        <FormControlLabel
        control = {<Checkbox 
        checked = {active}
        sx={{
          transform: "scale(0.8)"
        }}
        onChange={handleActive}
        />}
        label = "E-mail Ativo"
        sx = {{
          marginLeft: "10px",
        }}
        />
      </div>
      <div style={styles.container}>
        <div style={styles.textAreaContainer}>
          <ReactQuill
            ref={quillRef}
            value={html}
            onChange={handleChange}
            theme="snow"
            modules={modules}
            style={styles.quill}
          />
          <Button 
            sx={{
              fontSize: "12px",
              textTransform: "capitalize",
              backgroundColor: "#3B5998",
              color: "#fff",
              ":hover": { backgroundColor: "#30487B" }
            }} 
            onClick={openPreviewInNewTab} 
            endIcon={<Preview />}
            style={styles.button}
          >
            Pré-visualizar template
          </Button>
          <Button 
            sx={{
              fontSize: "12px",
              textTransform: "capitalize",
              backgroundColor: "#00A651",
              color: "#fff",
              marginLeft: "10px",
              ":hover": { backgroundColor: "#007D3D" }
            }} 
            onClick={handleSavetemplate} 
            endIcon={<Save />}
            style={styles.button}
          >
            Salvar e-mail
          </Button>
          <Dialog open={open} onClose={handleClose} aria-labelledby="modal-title">
        <DialogTitle id="modal-title">Status do Template</DialogTitle>
        <DialogContent>
          {message}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
        </Dialog>
        </div>
        <div style={styles.previewContainer}>
          <div style={styles.preview} dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </div>
    </>);
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '100vh',
    padding: '20px',
    gap: '20px',
  } as React.CSSProperties,
  textAreaContainer: {
    flex: '1',
    maxWidth: '600px',
    height: '80.1vh',
  } as React.CSSProperties,
  quill: {
    height: '100%',
    width: '100%',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
  } as React.CSSProperties,
  button: {
    padding: '10px 20px',
    marginTop: '50px',
    fontSize: '16px',
    cursor: 'pointer',
  } as React.CSSProperties,
  previewContainer: {
    flex: '1',
    maxWidth: '600px',
    height: '86vh',
    backgroundColor: 'white',
    padding: '10px',
    overflowY: 'auto',
    border: '1px solid #ccc',
  } as React.CSSProperties,
  preview: {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  } as React.CSSProperties,
};



export default CriadorTemplates;